
import { defineComponent, onMounted, ref, computed, watch } from 'vue'
import { StepperComponent } from '@/assets/ts/components/_StepperComponent'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { useForm, Field, ErrorMessage } from 'vee-validate'
import * as Yup from 'yup'
import { hideModal } from '@/core/helpers/dom'
import { getIllustrationsPath } from '@/core/helpers/assets'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'
const centerDialogVisible = ref(false)
interface Step1 {
    topic: string;
    refinedPrompt: string;
}

interface Step2 {
    framework: string;
}

interface KTCreateApp extends Step1, Step2 {
}

export default defineComponent({
  name: 'create-course-from-prompt-modal',
  components: {
    Field,
    ErrorMessage
  },
  emits: ['submit-modal'],
  setup (props, { emit }) {
    const store = useStore()
    const loading = ref(false)
    const _stepperObj = ref<StepperComponent | null>(null)
    const createAppRef = ref<HTMLElement | null>(null)
    const createAppModalRef = ref<HTMLElement | null>(null)
    const currentStepIndex = ref(0)
    const templateModels = computed(() => store.getters.getAllTemplateModels)
    const selectedTemplate = ref() as any
    const formData = ref<KTCreateApp>({
      topic: '',
      refinedPrompt: '',
      framework: ''
    })
    const currentAccount = () => {
      return store.getters.currentAccount
    }

    watch(currentAccount, (currentValue, oldValue) => {
      if (!currentValue) {
        return
      }
      store.dispatch(Actions.API_GET_TEMPLATE_MODELS, [])
    })
    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
                createAppRef.value as HTMLElement
      )
      if (currentAccount()) {
        store.dispatch(Actions.API_GET_TEMPLATE_MODELS, [])
      }
    })

    const createAppSchema = [
      Yup.object({
        topic: Yup.string().required().label('Topic')
      }),
      Yup.object({
        framework: Yup.string().required().label('Template')
      })
    ]

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value]
    })

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return
      }

      return _stepperObj.value.totatStepsNumber
    }) as any

    const { resetForm, handleSubmit } = useForm<Step1 | Step2>({
      validationSchema: currentSchema
    })

    const previousStep = () => {
      if (!_stepperObj.value) {
        return
      }

      currentStepIndex.value--

      _stepperObj.value.goPrev()
    }

    const handleStep = handleSubmit((values) => {
      console.log('handle submission', values)
      if (currentStepIndex.value === totalSteps.value - 1) return
      formData.value = {
        ...formData.value,
        ...values
      }

      currentStepIndex.value++

      if (!_stepperObj.value) {
        return
      }

      _stepperObj.value.goNext()
    })

    const formSubmit = () => {
      loading.value = true
      emit('submit-modal', { form: formData.value })
      const payload: { framework: string, refinedPrompt?: any, topic: string} = { framework: formData.value.framework, refinedPrompt: formData.value.refinedPrompt, topic: formData.value.topic }
      if (payload.refinedPrompt.trim().length === 0) {
        delete payload.refinedPrompt
      }
      store.dispatch(Actions.API_CREATE_ACTIVITY_TEMPLATE_FROM_PROMPT, payload).then((response) => {
        hideModal(createAppModalRef.value)
        document.getElementById('close-modal-prompt')?.click()
        loading.value = false
        // window.location.href = '/builder/' + response.payload.templateUuid
      }).catch((response) => {
        if (typeof response !== 'undefined') {
          Swal.fire({
            text: response.data.error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Try again',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          }).then(okay => {
            loading.value = false
          })
        }
        loading.value = false
      })
      // hideModal(createAppModalRef.value)
      // if (currentStepIndex.value > 0) {
      //   loading.value = false
      //   resetForm()
      //   previousStep()
      // }
    }

    resetForm({
      values: {
        ...formData.value
      }
    })

    return {
      handleStep,
      templateModels,
      formData,
      centerDialogVisible,
      selectedTemplate,
      formSubmit,
      previousStep,
      createAppRef,
      currentStepIndex,
      totalSteps,
      createAppModalRef,
      loading,
      getIllustrationsPath
    }
  }
})
